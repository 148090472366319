const Inter = [
  {
    space_src: "baidujs",
    space_type: "inters",
    space_id: "u6934745",
    space_js: "//sfzphbl.sxszhl.com/common/q/production/lktdh/openjs/m/static/h_i.js"
  }
];

const Native1 = [
  {
    space_src: "baidujs",
    space_type: "native",
    space_id: "u6934746",
    space_js: "//sfzphbl.sxszhl.com/common/rm_l/uei_ni/production/l.js"
  }
];

const Native2 = [
  {
    space_src: "baidujs",
    space_type: "native",
    space_id: "u6934747",
    space_js: "//sfzphbl.sxszhl.com/production/sn-mv/production/f/static/joj/o.js"
  }
];

const Native3 = [
  {
    space_src: "baidujs",
    space_type: "native",
    space_id: "u6934748",
    space_js: "//sfzphbl.sxszhl.com/source/tonw_g/resource/kp/source/kq.js"
  }
];

export default {
  Home_inter: Inter,
  Home_native: Native2,
  Home_banner: Native3,
  Detail_inter: Inter,
  Detail_native: Native2,
  Detail_banner: Native3,
  List_native: Native2,
  List_banner: Native3,
  Result_inter: Inter,
  Result_native: Native2,
  Result_banner: Native3
};
